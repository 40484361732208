import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavbarBrand, Navbar as ReactStrapNavbar } from 'reactstrap';
import history from '../Utils/History';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'State/Authentication/Actions/AuthenticationActions';
import { getAuthenticationState, getUsername } from 'State/Authentication/Selectors/AuthenticationSelectors';

const links = {
  '/map/mpa': 'MPAs',
  '/map/annual-budgets': 'Annual Budgets',
  '/budget-pacing/overview': 'Budget Pacing Overview',
  '/budget-pacing/metrics': 'Budget Pacing Metrics',
  '/budget-validation': 'Budget Validation',
  '/bom/budget-orders': 'Budget Orders',
  '/bom/table-configuration': 'Budget Orders Table Configuration',
  '/media-calculators': 'Media Calculators',
  '/map/lob': 'LOB Config',
  '/map/exclude-cids': 'Exclude CIDs Config',
  '/dag/api-config': 'DAG API Config',
  '/kbp': 'Kenshoo BQ Pipeline',
  '/privacy': 'PRM Online Privacy Policy',
};

const NavbarWithoutRouter = () => {
  const dispatch = useDispatch();
  const username = useSelector(getUsername);
  const authState = useSelector(getAuthenticationState);
  const [isOpen, setIsOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const pathname = window?.location?.pathname ?? '';

  const headerTitle = useMemo(() => links[pathname], [pathname, links]);

  return (
    <ReactStrapNavbar color="primary" dark className="nav-bar">
      <NavbarBrand>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <Dropdown isOpen={isOpen} toggle={() => setIsOpen((prev) => !prev)}>
              <DropdownToggle color="primary" style={{ border: '1px solid #ffffff' }}>
                <FontAwesomeIcon icon="bars" className="ml-1 mr-1" />
              </DropdownToggle>
              <DropdownMenu>
                {Object.entries(links).map(([link, title]) => (
                  <DropdownItem style={{ padding: '0.75rem 2.5rem', ...(title !== headerTitle && { color: '#007bff' }) }} active={title === headerTitle} key={link} onClick={() => history.push(link)}>
                    {title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div style={{ display: 'flex', marginLeft: '1rem' }}>{headerTitle}</div>
          <div style={{ flex: 'auto' }} />
        </div>
      </NavbarBrand>

      <Dropdown isOpen={isUserMenuOpen} toggle={() => setIsUserMenuOpen((prev) => !prev)} direction="down">
        <DropdownToggle color="primary" caret>
          {username}
        </DropdownToggle>
        <DropdownMenu style={{ left: 'auto', right: 0 }}>
          {authState.isAdmin && (
            <DropdownItem href="/admin/users" style={{ color: '#007bff' }}>
              Admin
            </DropdownItem>
          )}
          {authState.isAdmin && <DropdownItem divider />}
          <DropdownItem onClick={() => dispatch(logout())}>Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </ReactStrapNavbar>
  );
};

export const Navbar = withRouter(NavbarWithoutRouter);
