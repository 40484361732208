import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import IdleTimer from 'react-idle-timer';

import { getAuthenticationState } from './State/Authentication/Selectors/AuthenticationSelectors';

import AddAnnualBudgetContainer from './Components/AnnualBudgets/AddAnnualBudget/AddAnnualBudgetContainer';
import AddLobContainer from './Components/Lob/AddLob/AddLobContainer';
import AddExcludeCidContainer from './Components/ExcludeCids/AddExcludeCid/AddExcludeCidContainer';
import AddMpaContainer from './Components/Mpa/AddMpa/AddMpaContainer';
import EditAnnualBudgetContainer from './Components/AnnualBudgets/EditAnnualBudget/EditAnnualBudgetContainer';
import EditMpaContainer from './Components/Mpa/EditMpa/EditMpaContainer';
import AnnualBudgetsDashboardContainer from './Components/AnnualBudgets/AnnualBudgetsDashboard/AnnualBudgetsDashboardContainer';
import LobDashboardContainer from './Components/Lob/LobDashboard/LobDashboardContainer';
import EditLobContainer from './Components/Lob/EditLob/EditLobContainer';
import EditExcludeCidContainer from './Components/ExcludeCids/EditExcludeCid/EditExcludeCidContainer';
import MpaDashboardContainer from './Components/Mpa/MpaDashboard/MpaDashboardContainer';
import ExcludeCidsDashboardContainer from './Components/ExcludeCids/ExcludeCidsDashboard/ExcludeCidsDashboardContainer';
import LoginContainer from './Components/Login/LoginContainer';
import NotFound from './Components/Errors/NotFound';
import { KenshooDashboard } from './Components/Kenshoo/KenshooDashboard';
import { BudgetValidationContainer } from './Components/BudgetValidation/BudgetValidationDashboard/BudgetValidationContainer';
import BudgetPacingDashboardContainer from './Components/BudgetPacing/BudgetPacingDashboard/BudgetPacingDashboardContainer';
import BudgetPacingMetricsContainer from './Components/BudgetPacing/BudgetPacingDashboard/BudgetPacingMetricsContainer';
// import { StemsDashboard } from 'Components/StemsConfig/StemsDashboard';
import { BudgetOrdersDashboardContainer } from 'Components/Bom/BomDashboard/BudgetOrdersDashboardContainer';
import { EditBudgetOrdersContainer } from 'Components/Bom/EditBom/EditBudgetOrdersContainer';
import { ReviewBudgetOrdersContainer } from 'Components/Bom/ReviewBom/ReviewBudgetOrdersContainer';
import { TableConfigurationContainer } from 'Components/Bom/TableConfiguration/TableConfigurationContainer';
import ApiConfigDashboardContainer from './Components/DagApiConfig/ApiConfigDashboard/ApiConfigDashboardContainer';
import EditApiConfigContainer from './Components/DagApiConfig/EditApiConfig/EditApiConfigContainer';
import PrivacyPolicy from './Components/Privacy/PrivacyPolicy';

import { ToastContainer } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCheck,
  faEdit,
  faExclamationTriangle,
  faCheckCircle,
  faQuestionCircle,
  faThumbsUp,
  faSort,
  faSortUp,
  faSortDown,
  faSearch,
  faTimes,
  faBars,
  faTrash,
  faSquare,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { ModalsConsumer, ModalsProvider } from './Contexts/ModalsContext';

import { Navbar, SessionTimeoutModal } from './Common';
import { ForecastingToolContainer } from './Components/ForecastingTool/ForecastingToolDashboard/ForecastingToolContainer';
import { ForecastOverviewContainer } from 'Components/ForecastingTool/ForecastingToolDashboard/ForecastOverviewContainer';
import MediaCalculators from 'Components/MediaCalculators/MediaCalculators';
import AuthRoute from 'Auth/AuthRoute';
import { logout } from 'State/Authentication/Actions/AuthenticationActions';

const fontAwesomeIconsList = [
  faCheck,
  faBars,
  faTrash,
  faEdit,
  faExclamationTriangle,
  faCheckCircle,
  faQuestionCircle,
  faThumbsUp,
  faSort,
  faSortUp,
  faSortDown,
  faSearch,
  faTimes,
  faSquare,
  faPlus,
];

library.add(...fontAwesomeIconsList);

export const App = () => {
  const authenticationState = useSelector(getAuthenticationState);
  const idleTimer: any = useRef();
  const dispatch = useDispatch();
  const timeoutDuration = 1000 * 60 * 15;

  const isAuthenticated = authenticationState['isAuthenticated'];

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  const handleBeforeUnload = () => {
    // Will only trigger if the user is authenticated and the browser tab is closed
    const navigationPerfEntries = performance.getEntriesByType('navigation');
    const hasRefreshOrBrowserTraversalTakenPlace =
      navigationPerfEntries[0].toJSON()['type'] === 'reload' ||
      navigationPerfEntries[0].toJSON()['type'] === 'navigate';

    if (isAuthenticated && !hasRefreshOrBrowserTraversalTakenPlace) {
      dispatch(logout());
    }
  };

  return (
    <>
      <ModalsProvider>
        {authenticationState['isAuthenticated'] && <Navbar />}
        <Switch>
          <AuthRoute exact path="/" component={LoginContainer} />
          <AuthRoute exact path="/kbp/" component={KenshooDashboard} />
          <AuthRoute exact path="/map/mpa" component={MpaDashboardContainer} />
          <AuthRoute exact path="/map/mpa/add" component={AddMpaContainer} />
          <AuthRoute
            exact
            path="/map/mpa/edit/:id"
            component={EditMpaContainer}
          />

          <AuthRoute
            exact
            path="/map/exclude-cids"
            component={ExcludeCidsDashboardContainer}
          />
          <AuthRoute
            exact
            path="/map/exclude-cids/add"
            component={AddExcludeCidContainer}
          />
          <AuthRoute
            exact
            path="/map/exclude-cids/edit/:excludeId"
            component={EditExcludeCidContainer}
          />

          <AuthRoute
            exact
            path="/map/annual-budgets"
            component={AnnualBudgetsDashboardContainer}
          />
          <AuthRoute
            exact
            path="/map/annual-budgets/add"
            component={AddAnnualBudgetContainer}
          />
          <AuthRoute
            exact
            path="/map/annual-budgets/edit/:id"
            component={EditAnnualBudgetContainer}
          />
          <AuthRoute exact path="/map/lob" component={LobDashboardContainer} />
          <AuthRoute exact path="/map/lob/add" component={AddLobContainer} />
          <AuthRoute
            exact
            path="/map/lob/edit/:id"
            component={EditLobContainer}
          />
          <AuthRoute
            exact
            path="/budget-validation/"
            component={BudgetValidationContainer}
          />
          <AuthRoute
            exact
            path="/budget-pacing/overview"
            component={BudgetPacingDashboardContainer}
          />
          <AuthRoute
            exact
            path="/budget-pacing/metrics"
            component={BudgetPacingMetricsContainer}
          />
          <AuthRoute
            exact
            path="/forecasting-tool/"
            component={ForecastingToolContainer}
          />
          <AuthRoute
            exact
            path="/forecasting-tool/overview"
            component={ForecastOverviewContainer}
          />
          {/* <AuthRoute exact path="/stems-config" component={StemsDashboard} /> */}
          <AuthRoute
            exact
            path="/bom/budget-orders"
            component={BudgetOrdersDashboardContainer}
          />
          <AuthRoute
            exact
            path="/bom/edit-budget-orders"
            component={EditBudgetOrdersContainer}
          />
          <AuthRoute
            exact
            path="/bom/review-budget-orders"
            component={ReviewBudgetOrdersContainer}
          />
          <AuthRoute
            exact
            path="/bom/table-configuration"
            component={TableConfigurationContainer}
          />

          <AuthRoute
            exact
            path="/dag/api-config"
            component={ApiConfigDashboardContainer}
          />
          <AuthRoute
            exact
            path="/dag/api-config/edit/:id"
            component={EditApiConfigContainer}
          />
          <AuthRoute
            exact
            path="/media-calculators"
            component={MediaCalculators}
          />

          <AuthRoute exact path="/privacy" component={PrivacyPolicy} />

          <AuthRoute exact path="/not-found" component={NotFound} />
          <Route render={() => <Redirect to="/not-found" />} />
        </Switch>
        <ModalsConsumer>
          {(modalsState) => {
            return (
              modalsState && (
                <>
                  {modalsState.isLogoutModalOpen && (
                    <SessionTimeoutModal
                      isModalOpen={modalsState.isLogoutModalOpen}
                      handleToggleModal={modalsState.handleToggleLogoutModal}
                    />
                  )}
                  <IdleTimer
                    ref={idleTimer}
                    element={document}
                    onIdle={() => {
                      isAuthenticated &&
                        modalsState.handleToggleLogoutModal(isAuthenticated);
                    }}
                    timeout={timeoutDuration}
                  />
                </>
              )
            );
          }}
        </ModalsConsumer>
        <ToastContainer autoClose={5000} hideProgressBar={true} />
      </ModalsProvider>
    </>
  );
};

export default App;
