import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppPage, PageHeader, WithLoading } from '../../../Common';
import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getBudgetPacingState } from '../../../State/BudgetPacing/Selectors/BudgetPacingSelectors';
import { getBudgetPacingMetrics } from '../../../State/BudgetPacing/Actions/BudgetPacingActions';
import BudgetPacingMetrics from './BudgetPacingMetrics';

const BudgetPacingMetricsWithLoading = WithLoading(BudgetPacingMetrics);

const BudgetPacingMetricsContainer = () => {
  const budgetPacingState = useSelector(getBudgetPacingState);
  const username = useSelector(getUsername);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!budgetPacingState.getBudgetPacingMetricsListLoadable.isReceived) {
      dispatch(getBudgetPacingMetrics());
    }
  }, [budgetPacingState.getBudgetPacingMetricsListLoadable.isReceived, dispatch]);

  return (
    <>
      <PageHeader style={{ marginBottom: '2.5rem' }}>Budget Pacing Metrics</PageHeader>
      <AppPage>
        <BudgetPacingMetricsWithLoading loadable={budgetPacingState.getBudgetPacingMetricsListLoadable} budgetMetricsData={budgetPacingState.budgetPacingMetricsEntities} userName={username} />
      </AppPage>
    </>
  );
};

export default BudgetPacingMetricsContainer;
